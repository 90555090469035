import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./styles/skeletonLoading.scss";
import * as styles from "./styles/variables.module.scss";
import { isDocumentPreviewDetached } from "components/store/application.reducers";
import { useSelector } from "react-redux";


const { skeletonBackgroundColor, skeletonButtonColor, skeletonHighlightColor } = styles;

export const BypassCIRSkeleton = () => {
    const isPreviewDetached = useSelector(isDocumentPreviewDetached);

    return <div className="grid-document-preview-panel-loading skeleton flex-column d-flex align-content-stretch h-100">
        <SkeletonTheme baseColor={skeletonBackgroundColor} highlightColor={skeletonHighlightColor}>
            {!isPreviewDetached && <div className="d-flex justify-content-between align-items-center w-100">
                <Skeleton className="title-loading-skeleton my-3" baseColor={skeletonButtonColor} />
                <Skeleton className="button-loading-skeleton ms-4"
                          containerClassName={"min-max-btn-group buttons-bar-skeleton d-flex me-4 my-3"}
                          baseColor={skeletonButtonColor} count={2} />
            </div>
            }
            <div className="d-flex bg-skeleton-dark-grey rounded-8 py-3 mt-0 flex-column flex-grow-1 ">
                <BypassCIRSkeletonActions />
                <div className="bg-natural-white rounded-8 mx-3 flex-grow-1" />
            </div>
        </SkeletonTheme>
    </div>;
};

export const BypassCIRSkeletonActions = () => {
    return <SkeletonTheme baseColor={skeletonBackgroundColor} highlightColor={skeletonHighlightColor}>
        <div className="d-flex justify-content-between align-items-center w-100 py-3">
            <Skeleton className="button-loading-skeleton ms-4"
                      containerClassName={"d-flex align-items-center"}
                      baseColor={skeletonButtonColor} count={3} />

            <div className="d-flex align-items-center">
                <Skeleton className="button-loading-skeleton me-4" baseColor={skeletonButtonColor} />
                <Skeleton className="range-loading-skeleton me-4" baseColor={skeletonButtonColor} />
                <Skeleton className="button-loading-skeleton" baseColor={skeletonButtonColor} />
            </div>

            <Skeleton className="button-loading-skeleton me-4"
                      containerClassName={"d-flex align-items-center"}
                      baseColor={skeletonButtonColor} count={4} />
        </div>
    </SkeletonTheme>;
};

export default BypassCIRSkeleton;