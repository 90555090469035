import Immutable from 'seamless-immutable';

export const TASK_DETAILS_URL_PART = "/task/";
export const MY_HISTORY_URL_PART = "/taskhistory/";
export const SETTINGS_URL_PART = "/settings";
export const ERROR_POPUP_TYPE = "error";
export const CONFIRM_POPUP_TYPE = "confirm";
export const CURRENT_CONTEXT = "?contextSelector:switchContext=";
export const CONFIRMATION = "CONFIRMATION";
export const PROCESS_DETAILS_URL_PART = "/processtask/";
export const DOCUMENT_DETAILS_URL_PART = "/document/";
export const CONFIGURATION_URL_PART = "/configuration";
export const OTHER_SETTINGS_URL_PART = "/otherSettings";
// export const PREFERENCES_SETTINGS_URL_PART = "/preferences";
export const SUBSTITUTES_URL_PART = "/substitutes";
export const WORKFLOWS_URL_PART = "/workflows";
export const DUE_SETTINGS_URL_PART = "/dueSettings";
export const DEPARTMENTS_URL_PART = '/departments';
export const ROLES_URL_PART = '/roles';
export const PROJECTS_URL_PART = '/projects';
export const ORGANISATION_CHART_URL_PART = "/organisationChart";
export const COST_UNITS_URL_PART = '/costUnits';
export const HANDLED_TASK_POPUP_TYPE = "handled";

export const APPROVAL = ((typeof window.ENVIRONMENT !== 'undefined') ? window.ENVIRONMENT.APPROVAL_API : "/") + "jaxrs/";

export const BUSINESS_FEATURES = Immutable.from({
    ENABLE_EDITOR_PDF: 20,
});

export const DOCUMENT_PREVIEW_STATUS = Immutable.from({
    AVAILABLE: "AVAILABLE",
    WAITING: "WAITING",
    UNAVAILABLE: "UNAVAILABLE",
});


export const DOCUMENT_TYPE = Immutable.from({
    INVOICE: 1,
    EXPENSE_CLAIM: 2,
    LEAVE_REQUEST: 3,
    VOUCHER: 4,
    TIMESHEET: 5,
    COST_REQUEST: 6,
    SUPPLIER: 7,

    asString(key) {
        // Object.keys(this) ?
        for (let property in this) {
            if (this.hasOwnProperty(property)) {
                let value = this[property];
                if (isFinite(value) && value === key) {
                    return property;
                }
            }
        }
        return 'UNKNOWN_TYPE';
    },

    asXtype(key) {
        const xtypes = {
            [this.INVOICE]: "Visma.Approval.Document.InvoiceDocument",
            [this.EXPENSE_CLAIM]: "Visma.Approval.Document.ExpenseDocument",
            [this.LEAVE_REQUEST]: "Visma.Approval.Document.LeaveRequestDocument",
            [this.VOUCHER]: "Visma.Approval.Document.VoucherDocument",
            [this.TIMESHEET]: "Visma.Approval.Document.TimesheetDocument",
            [this.COST_REQUEST]: "Visma.Approval.Document.CostRequestDocument",
            [this.SUPPLIER]: "Visma.Approval.Document.SupplierDocument",
        };

        return xtypes[key];
    }
});

/**
 * possible actions for task
 */
export const TASK_ACTIONS = Immutable.from({
    APPROVE: 'approve',
    REJECT: "reject",
    POSTPONE: "postpone",
    FORWARD: "forward",
    REVIEW: "review",
    SEND_FOR_REVIEW: "sendforreview",
    EMAIL: "email", // always, ff specific
    OPEN: "open", // always, ff specific
    ADD_ATTACHMENT: "addAttachment",
    DELETE_ATTACHMENT: "deleteAttachment",
    REASSIGN: "reassign",
    NEXT_TASK: "moveToNext",
    PREVIOUS_TASK: "moveToPrevious",
    REAPPROVE: "reapprove",
    APPROVE_BATCH: "approveAndBatch"
});

export const TASK_ACTIONS_SHORTCUTS = Immutable.from({
    approve: 'A',
    reject: "R",
    postpone: "",
    forward: "F",
    review: "",
    sendforreview: "V",
    email: "E",
    open: "",
    addAttachment: "",
    deleteAttachment: "",
    reassign: "",
    moveToNext: "N",
    moveToPrevious: "P"
});

export const DOCUMENT_ACTIONS = Immutable.from({
    APPROVE_DIRECTLY: 'approveDirectly',
    SEND_FOR_APPROVAL: "sendfForApproval",
    REJECT_DIRECTLY: "rejectDirectly",
    EMAIL: "email"
});

/**
 * discriminator for status for asynchronous loading
 * @type {{LOADING: number, DONE: number, ERROR: number}}
 */
export const LOADING_STATUS = Immutable.from({
    LOADING: 1,
    DONE: 2,
    ERROR: 3
});

export const COMMENT_SAVING_STATUS = Immutable.from({
    START: 1,
    SAVING: 2,
    DONE: 3,
    ERROR: 4,
    IDLE: 5,
});

export const IMG_BASE = {
    width: 750,
    height: 1061
};

export const PDF_BASE = {
    width: 612,
    height: 800
};

const useHD = window.devicePixelRatio !== undefined && window.devicePixelRatio >= 1.5;
export const ZOOM_DIMENSIONS = [
    {
        width: 300,
        height: 480,
        imageWidth: useHD ? 700 : 300
    },
    {
        width: 530,
        height: 750,
        imageWidth: useHD ? 1000 : 700
    },
    {
        width: 700,
        height: 1061,
        imageWidth: useHD ? 1000 : 700
    },
    {
        width: 896,
        height: 1267,
        imageWidth: 1000
    },
    {
        width: 1000,
        height: 1414,
        imageWidth: 1000
    }
];

export const EDITITNG_TYPE = Immutable.from({
    ACCOUNT: "ACCOUNT_NAME",
    BOOLEAN: "BOOLEAN",
    COST_CENTER: "COST_CENTER_NAME",
    DATE: "DATE",
    DECIMAL: "DECIMAL",
    DECISIONS: "LA_ACTIONS",
    INTEGER: "INTEGER",
    INFO: "LA_INFO",
    STATUS: "LA_STATUS",
    NUMERIC: "number"
});

export const HISTORY_FILTERS = Immutable.from({
    COST_UNITS: "costUnits",
    ACCOUNT: "account",
    COLUMNS: "columns",
    SORTING: "sort",
    SORT_DIRECTION: "sord",
    SORT_INDEX: "sidx"
});

export const HOMEPAGE = "/";

/**
 item = {
 url: string ~ relative url
 label: string ~ key for translation
 children: [ item ] ~ optional, values for 2nd level if this item is selected
 }
 */
export const APPROVAL_MENU_ITEMS = Immutable.from([
    {
        url: '/',
        label: 'menu.myTasks',
        rightsToView: "taskListRightsToView",
        globalRoles: {
            isApprover: true
        },
        detailsView: {
            url: TASK_DETAILS_URL_PART
        }
    },
    {
        url: '/history',
        label: 'menu.myHistory',
        rightsToView: "myHistoryRightsToView",
        detailsView: {
            url: MY_HISTORY_URL_PART
        }
    },
    {
        url: '/taskoverview',
        label: 'menu.taskOverview',
        rightsToView: "taskOverviewRightsToView",
        routeURL: "#taskoverview",
    },
    {
        url: '/processoverview',
        label: 'menu.processOverview',
        rightsToView: "systemOverviewRightsToView",
        routeURL: "#systemoverview",
        detailsView: {
            url: PROCESS_DETAILS_URL_PART
        }
    },
    {
        url: '/documentoverview',
        label: 'menu.documentOverview',
        rightsToView: "documentOverviewRightsToView",
        routeURL: "#documentoverview",
        detailsView: {
            url: DOCUMENT_DETAILS_URL_PART
        }
    },
    {
        url: '/settings',
        label: 'menu.settings',
        routeURL: "#settings",
        children: [
            {
                url: '/approverNotifications',
                rightsToView: "manageMySettingsRightsToView",
                label: 'settings.approverNotifications',
                defaultView: {
                    url: '/settings'
                },
            },
            {
                url: '/substitute',
                rightsToView: "mySubstituteRightsToView",
                label: 'settings.substitutes'
            },
            {
                url: '/otherSettings',
                rightsToView: "preferencesRightsToView",
                label: 'settings.otherSettings',
            },
            // {
            //     url: '/preferences',
            //     rightsToView: "preferencesRightsToView",
            //     label: 'settings.otherSettings',
            // }
        ]
    },
    {
        url: '/configuration',
        label: 'menu.configuration',
        routeURL: "#settings",
        children: [
            {
                url: '/substitutes',
                rightsToView: "manageSubstitutesRightsToView",
                label: 'configuration.substitutes',
                defaultView: {
                    url: '/configuration'
                }
            },
            {
                url: DUE_SETTINGS_URL_PART,
                fullUrl: '/configuration/dueSettings',
                rightsToView: "manageCompanyDueSettingsRightsToView",
                label: 'configuration.dueSettings'
            },

            {
                url: '/managers',
                rightsToView: "nearestManagersRightsToView",
                label: 'configuration.managers'
            },
            {
                url: DEPARTMENTS_URL_PART,
                rightsToView: "departmentsRightsToView",
                label: 'configuration.departments'
            },
            {
                url: ROLES_URL_PART,
                rightsToView: "costCentersRightsToView",
                label: 'configuration.roles'
            },
            {
                url: PROJECTS_URL_PART,
                rightsToView: "projectsRightsToView",
                label: 'configuration.projects'
            },
            {
                url: '/organisationChart',
                rightsToView: "organizationChartRightsToView",
                label: 'configuration.organisationChart'
            },
            {
                url: '/costUnits',
                rightsToView: "costCentersRightsToView",
                label: 'configuration.costUnits'
            },
            {
                url: WORKFLOWS_URL_PART,
                rightsToView: "approvalSettingsRightsToView",
                label: 'configuration.workflows',
                detailsView: {
                    url: "/workflow/"
                }
            },
            {
                url: '/decisionTables',
                rightsToView: "approvalSettingsRightsToView",
                label: 'configuration.decisionTables',
                detailsView: {
                    url: "/details/"
                }
            }
        ]
    }
]);


export const PROCESS_TYPE = Immutable.from({
    ACTIVE: "active",
    COMPLETED: "completed"
});

export const WORKFLOW_PROCESS_STATUS = Immutable.from({
    /**
     * An active process
     */
    ACTIVE: 10,
    /**
     * The approval process is complete and the outcome is approval of the attached document.
     */
    APPROVED: 20,
    /**
     * The approval process is complete and the outcome is rejection of the attached document.
     */
    REJECTED: 30,
    /**
     * The approval process is complete acknowledged by the client application.
     */
    ACK: 40,
    /**
     * The approval process is complete and was archived.
     */
    ARCHIVED: 50,
    /**
     * The approval process is cancelled by the client application.
     */
    CANCELLED: 60,
    /**
     * The approval process was suspended as it was not possible to calculate a new
     * or updated workflow for it.
     */
    SUSPENDED: 70,
    asString(key) {
        // Object.keys(this) ?
        for (let property in this) {
            if (this.hasOwnProperty(property)) {
                let value = this[property];
                if (isFinite(value) && value === key) {
                    return property;
                }
            }
        }
        return 'UNKNOWN';
    }
});


export const DETAILS_STATUS = Immutable.from({
    /**
     * An active process
     */
    ACTIVE: 10,
    /**
     * The approval process is complete and the outcome is approval of the attached document.
     */
    APPROVED: 20,
    /**
     * The approval process is complete and the outcome is rejection of the attached document.
     */
    REJECTED: 30,
    /**
     * The approval process is cancelled by the client application.
     */
    CANCELED: 40,
    /**
     * The approval process is cancelled by the client application.
     */
    BLOCKED: 50,
    asString(key) {
        // Object.keys(this) ?
        for (let property in this) {
            if (this.hasOwnProperty(property)) {
                let value = this[property];
                if (isFinite(value) && value === key) {
                    return property;
                }
            }
        }
        return 'UNKNOWN';
    }
});

export const EVENT_TYPE = Immutable.from({
    0: "system",
    1: "approved",
    2: "reviewed",
    22: "reviewed_node",
    3: "rejected",
    4: "canceled",
    5: "forwarded",
    7: "noaction",
    8: "created",
    9: "auto_approved"
});

export const TRANSLATED_EVENT_TYPE = Immutable.from({
    1: "approved",
    2: "sentForReview",
    22: "reviewed",
    3: "rejected",
    4: "canceled",
    5: "forwarded",
    8: "created",
    9: "approved"
});

export const OVERVIEW_STATUS = Immutable.from({
    blockedTasks: 1,
    overdueTasks: 2,
    activeTasks: 3,
    futureTasks: 4,
    completedTasks: 5,
    allTasks: 6
});
export const DOCUMENT_OVERVIEW_STATUS = Immutable.from({
    BLOCKED: 6,
    IN_PROGRESS: 2,
    APPROVED: 3,
    REJECTED: 4,
    CANCELLED: 5
});
export const TASK_OVERVIEW_FILTERS = Immutable.from({
    COLUMNS: "columns",
    SORTING: "sort",
    SORT_DIRECTION: "sord",
    SORT_INDEX: "sidx"
});
export const TABLE_COLUMNS_TYPES = Immutable.from({
    AMOUNT: "amount",
    TEXT: "text",
    NUMBER: "number",
    SELECT: "select",
    ROLE_SELECT: "role_select",
    STATUS: "status",
    DATE_FILTER: "dateFilter",
    CHECKBOX: "checkbox",
    PROCESS_SELECT: "process_select",
    CLEAR_ALL_FILTERS: "clearAllFilters",
    NONE: "none",
    TASK_STATUS: "taskStatus",
    WORKFLOW_STATUS: "workflowStatus"
});
export const TABLE_COLUMNS_WIDTH = Immutable.from({
    LARGE: "large",
    SMALL: "small",
    FIX: "fix"
});

export const TASK_OVERDUE_STATUS = Immutable.from({
    TASK_OVERDUE: 'task-overdue',
    TASK_SOON_OVERDUE: 'task-soon-overdue',
    TASK_NOT_OVERDUE: 'task-not-overdue'
});
export const ERROR_TYPES = Immutable.from({
    SERVER_DOWN: 'serverDown',
    NOT_FOUND: 'notFound',
    400: 'serverError',
    401: 'serverError'
});

export const ATTACHMENT_SERVICE = Immutable.from({
    ATTACHMENT_STORE: 'ATTACHMENT_STORE',
    CIR: 'CIR',

    asString(key) {
        for (let property in this) {
            if (this.hasOwnProperty(property)) {
                let value = this[property];
                if (isFinite(value) && value === key) {
                    return property;
                }
            }
        }
        return 'UNKNOWN_TYPE';
    }
});

export const MONTHS = Immutable.from({
    january: "january",
    february: "february",
    march: "march",
    april: "april",
    may: "may",
    june: "june",
    july: "july",
    august: "august",
    september: "september",
    october: "october",
    november: "november",
    december: "december"
});

export const DAYS = Immutable.from({
    monday: "monday",
    tuesday: "tuesday",
    wednesday: "wednesday",
    thursday: "thursday",
    friday: "friday",
    saturday: "saturday",
    sunday: "sunday"
});

export const KEYS = Immutable.from({
    TAB: 9,
    ENTER: 13,
    ESC: 27,
    ARROW_LEFT: 37,
    UP_ARROW: 38,
    ARROW_RIGHT: 39,
    DOWN_ARROW: 40,
    A_KEY: 65,
    C_KEY: 67,
    E_KEY: 69,
    F_KEY: 70,
    L_KEY: 76,
    R_KEY: 82,
    T_KEY: 84,
    V_KEY: 86,
    W_KEY: 87
});

export const DETAILS_VIEW_TYPE = Immutable.from({
    TASK: "task",
    HISTORY_TASK: "historyTask",
    PROCESS: "process",
    DOCUMENT: "document"
});

export const LANGUAGES_CODE = Immutable.from({
    "da": "da-DK",
    "en": "en-GB",
    "fi": "fi-FI",
    "sv": "sv-SE",
    "nb": "nb-NO",
    "no": "nb-NO",
    "nl": "nl-NL"
});
export const MY_TASKS_DATES = Immutable.from({
    DOC_DUE: "documentDueDate",
    TASK_DUE: "dueDate",
    TASK_RECEIVED: "activatedDate"
});
export const MY_TASKS_DATES_ORDER = Immutable.from({
    documentDueDate: 6,
    activatedDate: -8,
    dueDate: 10
});
export const DETAILS_DISPLAY_LAYOUT = Immutable.from({
    ONLY_PAGES: "onlyPages",
    ONLY_THUMBNAILS: "onlyThumbs",
    PAGES_AND_THUMBNAILS: "pagesAndThumbnails"

});
export const RULE_TYPE = Immutable.from({
    BY_EMPLOYEE: "Visma.Approval.Rules.FindByEmployeeRoleAction",
    BY_LIST: "Visma.Approval.Rules.ListOfApproversAction",
    BY_COST_UNITS: "Visma.Approval.Rules.FindByRoleAction",
    BY_PROJECT_MANAGER: "Visma.Approval.Rules.FindByProjectManagerAction",
    BY_DEPARTMENT_MANAGER: "Visma.Approval.Rules.FindByDepartmentAction",
    BY_ORGANISATION_CHART: "Visma.Approval.Rules.FindByOrgChartRoleAction",
    BY_GENERAL_APPROVER: "Visma.Approval.Rules.FindByGeneralApproverAction",
    BY_NEAREST_MANAGER: "Visma.Approval.Rules.FindNearestManagerAction"
});

export const RULE_TYPES = {
    "Visma.Approval.Rules.FindByEmployeeRoleAction": {
        translationKey: "BY_EMPLOYEE",
        key: "EMPLOYEE_ROLE",
        xtype: "Visma.Approval.Rules.FindByEmployeeRoleAction"
    },

    "Visma.Approval.Rules.ListOfApproversAction": {
        translationKey: "BY_LIST",
        key: "APPROVERS_LIST",
        xtype: "Visma.Approval.Rules.ListOfApproversAction"
    },
    "Visma.Approval.Rules.FindByRoleAction": {
        translationKey: "BY_COST_UNITS",
        key: "ROLE",
        xtype: "Visma.Approval.Rules.FindByRoleAction"
    },
    "Visma.Approval.Rules.FindByProjectManagerAction": {
        translationKey: "BY_PROJECT_MANAGER",
        key: "PROJECT_MANAGER_ROLE",
        xtype: "Visma.Approval.Rules.FindByProjectManagerAction"
    },
    "Visma.Approval.Rules.FindByDepartmentAction": {
        translationKey: "BY_DEPARTMENT_MANAGER",
        key: "DEPARTMENT_ROLE",
        xtype: "Visma.Approval.Rules.FindByDepartmentAction"
    },
    "Visma.Approval.Rules.FindByOrgChartRoleAction": {
        translationKey: "BY_ORGANISATION_CHART",
        key: "ORGCHART_ROLE",
        xtype: "Visma.Approval.Rules.FindByOrgChartRoleAction"
    },
    "Visma.Approval.Rules.FindByGeneralApproverAction": {
        translationKey: "BY_GENERAL_APPROVER",
        key: "GENERAL_APPROVER_ROLE",
        xtype: "Visma.Approval.Rules.FindByGeneralApproverAction"
    },
    "Visma.Approval.Rules.FindNearestManagerAction": {
        translationKey: "BY_NEAREST_MANAGER",
        key: "PROJECT_MANAGER_ROLE",
        xtype: "Visma.Approval.Rules.FindNearestManagerAction"
    }
};

export const BATCH_ACTIONS = Immutable.from({
    CONFIRM: "confirm",
    CANCEL: "cancel"
});


export const AMOUNT_RULE_TYPES = Immutable.from({
    "Visma.Approval.Rules.AmountConditionBelow": {
        translationKey: "LIMIT_BELOW",
        xType: "Visma.Approval.Rules.AmountConditionBelow"
    },
    "Visma.Approval.Rules.AmountCondition": {
        translationKey: "LIMIT_ABOVE",
        xType: "Visma.Approval.Rules.AmountCondition"
    },
    "Visma.Approval.Rules.AmountConditionBetween": {
        translationKey: "LIMIT_BETWEEN",
        xType: "Visma.Approval.Rules.AmountConditionBetween"
    }
});

export const TIME_RULE_TYPES = Immutable.from({
    "Visma.Approval.Rules.NumberOfDaysConditionBelow": {
        translationKey: "DAYS_BELOW",
        xType: "Visma.Approval.Rules.NumberOfDaysConditionBelow"
    },
    "Visma.Approval.Rules.NumberOfDaysConditionBetween": {
        translationKey: "DAYS_BETWEEN",
        xType: "Visma.Approval.Rules.NumberOfDaysConditionBetween"
    },
    "Visma.Approval.Rules.NumberOfDaysCondition": {
        translationKey: "DAYS_ABOVE",
        xType: "Visma.Approval.Rules.NumberOfDaysCondition"
    }
});

export const WORKFLOW_DETAILS = 'workflow-details';
export const DOCUMENT_DETAILS = 'document-details';
export const DOCUMENT_PREVIEW = 'document-preview';
export const COMMENTS = 'comments';
export const EDITOR = 'editor';
export const ACCOUNTING_GRID = 'accounting-grid';

export const PANELS_BY_DOCTYPE_AND_INTEGRATION = {
    DEFAULT: {
        [DOCUMENT_DETAILS]: true,
        [WORKFLOW_DETAILS]: true,
        [DOCUMENT_PREVIEW]: true,
        [COMMENTS]: true,
        [EDITOR]: false,
        [ACCOUNTING_GRID]: false
    },

    INVOICE: {
        "DocumentCenter": {
            [DOCUMENT_DETAILS]: true,
            [WORKFLOW_DETAILS]: true,
            [DOCUMENT_PREVIEW]: true,
            [COMMENTS]: true,
            [EDITOR]: false,
            [ACCOUNTING_GRID]: true
        },
        "Visma.net Financials": {
            [DOCUMENT_DETAILS]: true,
            [WORKFLOW_DETAILS]: true,
            [DOCUMENT_PREVIEW]: true,
            [COMMENTS]: true,
            [EDITOR]: true,
            [ACCOUNTING_GRID]: false
        },
        "AccountView": {
            [DOCUMENT_DETAILS]: true,
            [WORKFLOW_DETAILS]: true,
            [DOCUMENT_PREVIEW]: true,
            [COMMENTS]: true,
            [EDITOR]: false,
            [ACCOUNTING_GRID]: false
        },
        "SolidBricks": {
            [DOCUMENT_DETAILS]: true,
            [WORKFLOW_DETAILS]: true,
            [DOCUMENT_PREVIEW]: true,
            [COMMENTS]: true,
            [EDITOR]: false,
            [ACCOUNTING_GRID]: false
        },
    },

    EXPENSE_CLAIM: {
        "Visma.net Expense": {
            [DOCUMENT_DETAILS]: true,
            [WORKFLOW_DETAILS]: true,
            [DOCUMENT_PREVIEW]: true,
            [COMMENTS]: true,
            [EDITOR]: true,
            [ACCOUNTING_GRID]: false
        },
        "Visma.net Financials": {
            [DOCUMENT_DETAILS]: true,
            [WORKFLOW_DETAILS]: true,
            [DOCUMENT_PREVIEW]: true,
            [COMMENTS]: true,
            [EDITOR]: true,
            [ACCOUNTING_GRID]: false
        },
    },

    LEAVE_REQUEST: {
        "Visma.net Absence": {
            [DOCUMENT_DETAILS]: true,
            [WORKFLOW_DETAILS]: true,
            [DOCUMENT_PREVIEW]: true,
            [COMMENTS]: true,
            [EDITOR]: true,
            [ACCOUNTING_GRID]: false
        }
    },

    TIMESHEET: {
        "Visma.net Absence": {
            [DOCUMENT_DETAILS]: true,
            [WORKFLOW_DETAILS]: true,
            [DOCUMENT_PREVIEW]: true,
            [COMMENTS]: true,
            [EDITOR]: false,
            [ACCOUNTING_GRID]: false
        },
        "Visma.net Financials": {
            [DOCUMENT_DETAILS]: true,
            [WORKFLOW_DETAILS]: true,
            [DOCUMENT_PREVIEW]: false,
            [COMMENTS]: true,
            [EDITOR]: true,
            [ACCOUNTING_GRID]: false
        }
    },

    VOUCHER: {
        "Visma Business Cloud": {
            [DOCUMENT_DETAILS]: true,
            [WORKFLOW_DETAILS]: true,
            [DOCUMENT_PREVIEW]: true,
            [COMMENTS]: true,
            [EDITOR]: true,
            [ACCOUNTING_GRID]: false
        },
        "Visma Business": {
            [DOCUMENT_DETAILS]: true,
            [WORKFLOW_DETAILS]: true,
            [DOCUMENT_PREVIEW]: true,
            [COMMENTS]: true,
            [EDITOR]: false,
            [ACCOUNTING_GRID]: false
        }
    },

    //Purchase order
    COST_REQUEST: {
        "Visma Business": {
            [DOCUMENT_DETAILS]: true,
            [WORKFLOW_DETAILS]: true,
            [DOCUMENT_PREVIEW]: true,
            [COMMENTS]: true,
            [EDITOR]: false,
            [ACCOUNTING_GRID]: false
        }
    },

    //Information
    SUPPLIER: {
        "Visma Control": {
            [DOCUMENT_DETAILS]: true,
            [WORKFLOW_DETAILS]: true,
            [DOCUMENT_PREVIEW]: true,
            [COMMENTS]: true,
            [EDITOR]: false,
            [ACCOUNTING_GRID]: false
        }
    }
}


export const REAPPROVAL_TOOLTIP = Immutable.from({
    'SUBSTITUTE': "substituteOnNewTask",
    'REVIEW': "taskToReview",
    'FORWARDED': "taskForwarded",
    'REASSIGNED': "taskReassigned"
});

export const SIZE_PATTERN = "<%=size%>";
export const PAGE_PATTERN = "<%= page%>";
export const OK_CIR_STATUS = "OK";
export const COMPLETED_CIR_STATUS = "COMPLETED";
export const IMAGE_MIME_TYPE = "image/";

export const IF_MATCH_HEADER = "If-Match";

export const UNAUTHORIZED_STATUS = 401;

    export const NOT_OK = "NOT_OK";
export const COMMENTS_INPUT_MAX_LENGTH = 500;
export const COMMENTS_INPUT_THRESHOLD_LENGTH = 450;